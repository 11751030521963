<template>
    <div>
        <nf-breadcrumb :breadList='breadList'></nf-breadcrumb>
        <div class='detailContent'>
            <h2>南繁简介及历程</h2>
            <p class='time'>2018.05.16</p>
            <div class='content'>南繁是利用我国南方特别是海南岛南部地区冬春季节气候温暖的优势条件，将夏季在北方种植的农作物育种材料，于冬春季节在南方再种植一季或者两季的农作物育种方式。</div>
            <div class='content'>南繁在保障国家粮食安全、缩短农作物育种周期、促进现代农业发展和农民增收、培育科研育种人才等方面做出了突出贡献。南繁加代选育可使农作物新品种选育周期缩短了1/3至1/2，
                南繁基地已成为我国新品种选育的“孵化器”和“加速器”、保障农业生产用种的“调节库”和种子质量天然的“鉴定室”。近年来全国近30个省份800多家科研院所、高等院校及科技型企业约6000多名农业科技专家、学者，来海南从事南繁育种工作。
                种植的农作物育种材料，于冬春季节在南方再种植一季或者两季的农作物育种方式。</div>
            <div class='content'>我国南繁始于1956年，到目前可分为四个阶段。</div>
            <div class='content'>
                <span>第一阶段，探索实践阶段。</span>1956年到上世纪60年代，以丁颖、吴绍骙等为代表的老一代专家提出了南繁加代理论，辽宁、湖南、山东、河南、四川等省专家及技术人员开始了南繁的探索和实践。</div>
            <div class='content'><span>第二阶段，逐步兴起阶段。</span>上世纪70年代，南繁进入兴起阶段，1971年繁制种面积超过25万亩，涉及28个省400多家单位7000多南繁人员，为强化南繁管理，1972年国务院批转农林部《关于当前种子工作的报告》，明确“南繁种子原则上只限于科研项目”。</div>
            <div class='content'><span>第三阶段，稳定发展阶段。</span>上世纪80年代到2008年，南繁进入稳定发展阶段，1983年农牧渔业部颁布《南繁工作试行条例》，1995年农业部和海南省决定成立国家南繁工作领导小组，1997年农业部、海南省政府印发《农作物种子南繁工作管理办法（试行）》, 南繁管理逐步规范。</div>
            <div class='content'><span>第四阶段，全面提升阶段。</span>2009年以来，南繁进入全面提升的新阶段。2009年，国务院印发《关于推进海南国际旅游岛建设发展的若干意见》，明确建设南繁育制种基地，南繁基地建设上升为国家战略。</div>
            <div class='content'>2013年，习近平总书记视察海南时强调：“南繁基地是国家宝贵的农业科研平台，一定要建成集科研、生产、销售、科技交流、成果转化为一体的服务全国的重要基地”。</div>
            <div class='content'>2014年，汪洋副总理到南繁基地考察，要求各级政府、相关部门“要像保护大熊猫一样，保护国家南繁基地”。 </div>

            <div class='content'>2015年，《国家南繁科研育种基地（海南）建设规划（2015-2025年）》印发实施，南繁基地建设成为重大基础性、战略性国家工程，南繁全面步入规范化、现代化发展的新阶段。</div>
            <div class='content'>回顾60年南繁发展，我省立足琼岛、服务全国，为全国农业科研发展和确保国家粮食安全做出了重大贡献。据统计，南繁60多年以来，全国育成的农作物新品种中，70%以上的品种都经过南繁。近几年全国近30个省份800多家科研院所、高等院校及科技型企业约6000多名农业科技专家、学者，来海南从事南繁育种工作。 </div>

        </div>
        
    </div>
</template>
<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
export default {
    components: {
        nfBreadcrumb
    },
    data() {
        return {
             breadList: [{
                name: '关于南繁',
                path: '/aboutNanfan'
            }, {
                name: '详情'
            }]
        };
    }
};
</script>
<style lang="less" scoped>
    h2{
        font-size: 30px;
        color: #333333;
        font-weight: bold;
        text-align: center;
    }
    .time{
        color: #5c5c5c;
        font-size: 16px;
        margin:20px 0 20px;
        padding-bottom:38px;
        text-align: center;
        border-bottom:1px solid #d9d9d9;
    }
    .detailContent{
        width:1000px;
        margin:0 auto;
        line-height: 30px;
        font-size: 16px;
    }
    .content{
        text-indent: 2em;
        padding-bottom:30px;
        span{
            font-weight: bold;
            font-size: 18px;
        }
    }
</style>